//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Footer",
  data() {
    return {
      footerData: {
        logo: null,
        imageUrl: null,
        contact: '',
        siteCredits: '',
        social:{
          data:{
            description:'',
            title: '',
          },
          items:[],
        },
        jewelry:{
          data:{
            title: '',
          },
          items:[],
        },
        events:{
          data:{
            title: '',
          },
          items:[],
        },
      }
    }
  },
  mounted(){
    this.getAndBuildFooterData()
    if(window && window.NDRSL) {
      window.NDRSL.init("63599973d7079c39e211c95c")
    }
  },
  methods:{
    async getFooterData(){
      const content = (await this.$axios.get(`/contents/footer`)).data
      const vueObj = {}
      Object.assign(vueObj, content.details)
      return vueObj
    },
    buildLinkMenuList(menu){
      let localMenu = {
        data:{
          title: '',
        },
        items:[],
      }

      if(menu && menu.length > 0){
        for(const menuItem of menu){
          if(menuItem.component === "section-properties"){
            localMenu.data.title = menuItem.title && menuItem.title.fieldValue ? menuItem.title.fieldValue.value : ''
          } else if(menuItem.component === "menu-item"){
            const jewelryObj = {}
            jewelryObj.name = menuItem.name && menuItem.name.fieldValue ? menuItem.name.fieldValue.value : ''
            jewelryObj.link = menuItem.link && menuItem.link.fieldValue ? menuItem.link.fieldValue.value : ''
            localMenu.items.push(jewelryObj)
          }
        }
      }
      return localMenu
    },
    buildFooterData(vueObj){
      if (vueObj) {
        this.footerData.imageUrl = vueObj.image && vueObj.image.fieldValue ? vueObj.image.fieldValue.fullFileUrl : null
        this.footerData.logo = vueObj.logo && vueObj.logo.fieldValue ? vueObj.logo.fieldValue.fullFileUrl : null
        this.footerData.contact = vueObj.contact && vueObj.contact.fieldValue ? vueObj.contact.fieldValue.value : ''
        this.footerData.siteCredits = vueObj.sitecredits && vueObj.sitecredits.fieldValue ? vueObj.sitecredits.fieldValue.value : ''
        if(vueObj.social && vueObj.social.length > 0){
          for(const socialItem of vueObj.social){
            if(socialItem.component === "section-properties"){
              this.footerData.social.data.description = socialItem.description && socialItem.description.fieldValue ? socialItem.description.fieldValue.value : ''
              this.footerData.social.data.title = socialItem.title && socialItem.title.fieldValue ? socialItem.title.fieldValue.value : ''
            } else if(socialItem.component === "social-item"){
              const socialObj = {}
              socialObj.icon = socialItem.mdi_icon && socialItem.mdi_icon.fieldValue ? socialItem.mdi_icon.fieldValue.value : ''
              socialObj.link = socialItem.url && socialItem.url.fieldValue ? socialItem.url.fieldValue.value : ''
              this.footerData.social.items.push(socialObj)
            }
          }
        }
        this.footerData.jewelry = this.buildLinkMenuList(vueObj.jewelry)
        this.footerData.events = this.buildLinkMenuList(vueObj.events)
      }
    },
    async getAndBuildFooterData(){
      const vueObj = await this.getFooterData()
      this.buildFooterData(vueObj)
    },
    toTop(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
